import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const privacy = () => (
  <Layout pageInfo={{ pageName: "privacy" }}>
    <SEO title="Privacy Policy" description="This Privacy Policy (this 'Policy') sets forth Shoprises's policy with respect to information" path="privacy" />
    <div className='terms'>
      <div className='terms-title'>Privacy Policy</div>
      <div className='text'>
         This Privacy Policy (this "Policy") sets forth Shoprises's policy with respect to information, including personally identifiable data (such personally identifiable data "Personal Information"), that is collected from you through the Site or through our other service offerings (collectively, including the Site, the "Services"). Certain Services may be made available to you on websites owned by our end customers (each, an "End Customers"), and the information collected via these embedded widgets ("Tools") is directed to Shoprises and governed by this Policy.
         <br />
         <br />
         The Services and our business may change from time to time. As a result, at times it may be necessary for Shoprises to make changes to this Policy. Shoprises reserves the right to update this Policy at any time and from time to time without prior notice. Please review this Policy periodically, and especially before you provide any Personal Information. This Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Policy shall indicate your agreement with the terms of such revised Policy.
      </div>

      <div className='title'>1. Information Collected</div>
      <div className="text">
         When you interact with us through the Site or other Services, we may collect Personal Information and other information from you, as further described below: 
      </div>

      <div className='title'>Personal Information That You Provided</div>
      <div className="text">
         We collect Personal Information from you when you voluntarily provide such information, such as when you contact us with inquiries, subscribe to our or a Shoprises End Customer's e-mail newsletter, register for access to the Services or use certain Services. For instance, Shoprises may collect the following information about you: name, address, zip code, telephone number and e-mail address. The information collected may vary. For example, our End Customers may customize the data that is requested via the Tools and may collect other information, such as your favorite color or birthdate. 
      </div>

     <div className='title'>Other Information</div>
     <div className="text">
         Non-Identifiable Data: When you interact with Shoprises through the Services, we receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify you, unless it is combined with Personal Information. Shoprises may store such information itself or such information may be included in databases owned and maintained by Shoprises affiliates, agents or service providers. We may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Site, and the domain names of our visitors' Internet service providers. It is important to note that Shoprises uses no Personal Information in this process.
         <br />
         <br />
      <div className="text">
         Cookies: In providing the Services, we may use a technology called "cookies." A cookie is a piece of information that the computer that hosts our Site gives to your browser when you access the Site or the Tools. Our cookies help provide additional functionality to the Services and help us analyze the Services usage more accurately. Certain information collected through the use of cookies may be used to serve you more relevant online advertisements by us or third parties. We may, or a third party may, place and recognize a unique cookie on your browser specifically to identify you for the purposes of providing you with customized ads or content across channels. The cookies may be linked to data you voluntarily submit to us, e.g., your email address, which we may share with a third party. If we share such Personal Information it will be in hashed, non-human readable form for security purposes only. If you'd like to opt-out of such collection and use of data, please go to the Digital Advertising Alliance's consumer opt-out page here: www.aboutads.info/choices or the Network Advertising Initiative's opt-out mechanism here: http://networkadvertising.org/optout_nonppii.asp. For the avoidance of doubt, except with respect to EU Data, we may share with third parties'data collected from your use of websites of End Customers with third parties and such third parties might use that data, together with data collected by such third parties from other sources to cause advertisements to be targeted to you. Such data typically includes the URL you are on. As used herein, "EU Data" means data of data subjects located in the European Economic Area (including the United Kingdom as of the Effective Date of this Privacy Policy).
      </div>
     </div>

     <div className='title'>2. Use of Information</div>
     <div className="text">
        We generally process your information when we need to do so to fulfill a contractual obligation (for example, to process your subscription payments to use the Shoprises platform), or where we or someone we work with needs to use your personal information for a reason related to their business (for example, to provide you with a service). These include:
        <ul>
           <li>preventing risk and fraud</li>
           <li>answering questions or providing other types of support</li>
           <li>providing and improving our products and services</li>
           <li>providing reporting and analytics</li>
           <li>testing out features or additional services</li>
           <li>assisting with marketing, advertising, or other communications</li>
        </ul>
         We only process personal information for these reasons after considering the potential risks to your privacy—for example, by providing clear transparency into our privacy practices, offering you control over your personal information where appropriate, limiting the information we keep, limiting what we do with your information, who we send your information to, how long we keep your information, or the technical measures we use to protect your information.
      </div>

      <div className='title'>3. Disclosure of Information</div>
      <div className="text">
         This Policy does not apply to any Personal Information collected by Shoprises other than Personal Information collected through the Services. This Policy shall not apply to any unsolicited information you provide to Shoprises through the Services or through any other means. This includes any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, "Unsolicited Information"). All Unsolicited Information shall be deemed to be non-confidential and Shoprises shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
      </div>

      <div className='title'>4. Security</div>
      <div className="text">
         Our teams work tirelessly to protect your information, and to ensure the security and integrity of our platform. Shoprises takes reasonable steps to protect the Personal Information provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure. This means we cannot guarantee the absolute security of your personal information. 
      </div>

      <div className='title'>5. Third Party Sites</div>
      <div className="text">
         This Policy applies only to the Services. The Site and other Services, including the Tools, may contain links to other websites not operated or controlled by Shoprises, including those operated by End Customers (the "Third Party Sites"). The policies and procedures described here do not apply to the Third Party Sites or services, or to the use of information submitted via a Tool on such End Customer's website by the applicable End Customer or its service providers. The links from the Site or placement of Tools on certain Third Party Sites does not imply that Shoprises endorses or has reviewed the Third Party Sites. We suggest contacting those sites, services, entities or persons directly for information on their privacy policies.
      </div>

      <div className='title'>6. Access to Information</div>
      <div className="text">
         We believe you should be able to access and control your personal information no matter where you live. Depending on how you use Shoprises, you may have the right to request access to, correct, amend, delete, port to another service provider, restrict, or object to certain uses of your personal information (for example, direct marketing). We will not charge you more or provide you with a different level of service if you exercise any of these rights.ivities or any other illegal or deceptive activities.
         <br />
         <br />
         Please note that if you send us a request relating to your personal information, we have to make sure that it is you before we can respond. In order to do so, we may ask to see documentation verifying your identity, which we will discard after verification.
      </div>
     
    </div>
  </Layout>
)

export default privacy
